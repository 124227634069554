
export default {
  name: 'IndexPage',
  data() {
    return {}
  },

  methods: {
    goTo(url) {
      if (!url) {
        return
      }

      window.open(url, '_blank')
    },
  }
}
